// useContentImages.ts
import { graphql, useStaticQuery } from 'gatsby'

type ContentfulImage = {
  assets: {
    edges: {
      node: {
        contentful_id: string
        url: string
      }
    }[]
  }
}

export const useContentImages = () => {
  const { assets }: ContentfulImage = useStaticQuery(graphql`
    query {
      assets: allContentfulAsset {
        edges {
          node {
            contentful_id
            url
          }
        }
      }
    }
  `)
  return assets.edges.map(({ node }) => node)
}
