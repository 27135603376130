import { graphql, useStaticQuery } from 'gatsby'
import React, { ImgHTMLAttributes } from 'react'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  relativePath: string
}

export const getPath = (relativePath: string) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(formats: AUTO, quality: 100)
            }
          }
        }
      }
    }
  `)

  const correctImage = data.allFile.edges.find(
    (_img: { node: { relativePath: string } }) =>
      _img.node.relativePath === relativePath
  )

  if (!correctImage) {
    return null
  }

  return correctImage?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
}

export const Image = ({ relativePath, ...rest }: ImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(formats: AUTO, quality: 100)
            }
          }
        }
      }
    }
  `)

  const correctImage = data.allFile.edges.find(
    (_img: { node: { relativePath: string } }) =>
      _img.node.relativePath === relativePath
  )

  if (!correctImage) {
    return null
  }

  return (
    <img
      {...rest}
      src={
        correctImage?.node?.childImageSharp?.gatsbyImageData?.images?.fallback
          ?.src
      }
    />
  )
}
